<template>
  <div class="relative">
    <select
      class="focus:outline-none block w-full cursor-pointer rounded-md border py-1 px-2 text-slate-500 shadow-sm"
      :class="
        invalid
          ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
          : 'border border-slate-300 focus:ring'
      "
      :id="id"
      :style="{
        borderColor: focus ? company.primary[500] : null,
        '--tw-ring-color':
          company.primary && company.primary[200] ? company.primary[200] : null,
      }"
      @keydown.enter.space.prevent="toggleOptions()"
      @mousedown.prevent="
        (event) => {
          event.target.focus();
          toggleOptions();
        }
      "
      @touchstart.prevent="
        (event) => {
          event.target.focus();
          toggleOptions();
        }
      "
      @focus="focus = true"
      @focusout="focus = false"
    >
      <option v-if="modelValue" selected>
        {{
          translatableLabel
            ? t(`${translatableLabel}.${getLabel(modelValue)}`)
            : getLabel(modelValue)
        }}
      </option>
      <option v-else-if="allOption" selected>
        {{ t("labels.all") }}
      </option>
      <option v-else-if="noneOption" selected>
        {{ t("labels.none") }}
      </option>
    </select>
    <div
      v-if="showOptions"
      class="absolute top-full left-0 z-20 w-full rounded-md border border-slate-300 bg-white"
    >
      <div class="max-h-60 overflow-y-scroll">
        <div
          v-if="allOption"
          :key="0"
          class="flex cursor-pointer items-center p-3"
          :style="{
            backgroundColor: hovers[0]
              ? company.primary[200]
              : modelValue == null
              ? company.primary[100]
              : null,
          }"
          @click="select(null)"
          @mouseover="hovers[0] = true"
          @mouseleave="hovers[0] = false"
        >
          {{ t("labels.all") }}
        </div>
        <div
          v-if="noneOption"
          :key="0"
          class="flex cursor-pointer items-center p-3"
          :style="{
            backgroundColor: hovers[0]
              ? company.primary[200]
              : modelValue == null
              ? company.primary[100]
              : null,
          }"
          @click="select(null)"
          @mouseover="hovers[0] = true"
          @mouseleave="hovers[0] = false"
        >
          {{ t("labels.none") }}
        </div>
        <div
          v-for="(option, index) in options"
          :key="option.value || option.id"
          class="flex cursor-pointer items-center p-3"
          :style="{
            backgroundColor: hovers[index + 1]
              ? company.primary[200]
              : modelValue &&
                (modelValue == option.value || modelValue == option.id)
              ? company.primary[100]
              : null,
          }"
          @click="select(option)"
          @mouseover="hovers[index + 1] = true"
          @mouseleave="hovers[index + 1] = false"
        >
          {{
            translatableLabel
              ? t(`${translatableLabel}.${getLabel(option)}`)
              : getLabel(option)
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  props: {
    allOption: {
      required: false,
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
    },
    invalid: {
      required: true,
      type: Boolean,
    },
    modelValue: {
      type: [Number, String],
    },
    noneOption: {
      required: false,
      type: Boolean,
    },
    optionLabel: {
      required: true,
      type: Array[String],
    },
    options: {
      required: true,
      type: Array[Object],
    },
    translatableLabel: {
      required: false,
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const focus = ref(false);

    const hovers = ref([]);

    const showOptions = ref(false);

    const toggleOptions = () => {
      showOptions.value = !showOptions.value;
    };

    const closeOptions = () => {
      setTimeout(() => {
        showOptions.value = false;
        hovers.value = [];
      }, 100);
    };

    const getLabel = (option) => {
      if (!option || !props.options?.length) return;
      if (!option.value && !option.id) {
        option = props.options.find(
          (opt) => opt.value == option || opt.id == option
        );
      }
      let parts = [];
      for (const key of props.optionLabel) {
        parts.push(option[key]);
      }
      return parts.join(" ");
    };

    const select = (option) => {
      emit(
        "update:modelValue",
        option ? (option.value ? option.value : option.id) : null
      );
      showOptions.value = false;
      hovers.value = [];
    };

    return {
      closeOptions,
      company: computed(() => store.state.company.company),
      focus,
      getLabel,
      hovers,
      select,
      showOptions,
      t,
      toggleOptions,
      waiting: computed(() => store.state.company.waiting),
    };
  },
};
</script>
