<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.partner_companies") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card mb-4">
      <custom-table
        :fields="fields"
        :items="filteredPartners"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="name"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/partners/${id}`)"
      >
        <template #filters>
          <form-select
            class="mt-2 w-40 sm:mt-0"
            :allOption="true"
            id="partner-type-filter"
            :invalid="false"
            :optionLabel="['label']"
            :options="types"
            translatableLabel="partner_types"
            v-model="filterValue"
          />
        </template>
        <template #cell(types)="{ item }">
          <div class="flex items-center">
            {{
              item.types
                .map((partnerType) =>
                  t("partner_types." + getTypeName(partnerType, types))
                )
                .join(", ")
            }}
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import FormSelect from "../components/form/FormSelect.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getTypeName } from "../utils/typeUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, FormSelect },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const partners = computed(() => store.state.partner.partners);
    const types = computed(() => store.state.partner.types);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.partner.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const filterValue = ref(null);

    const filteredPartners = computed(() => {
      if (!filterValue.value) return partners.value;
      return partners.value.filter((partner) =>
        partner.types.includes(filterValue.value)
      );
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "types",
        label: "type",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
    ]);

    const searchFields = ref(["name", "email", "type", "phone"]);
    const sortFields = ref(["name", "email", "type", "phone"]);

    onMounted(() => {
      if (!partners.value.length) {
        store.dispatch("partner/getPartners");
      }
      if (!types.value.length) {
        store.dispatch("partner/getTypes");
      }
    });

    return {
      company,
      fields,
      filteredPartners,
      filterValue,
      getTypeName,
      router,
      searchFields,
      sortFields,
      t,
      types,
      waiting,
    };
  },
};
</script>
